*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

.\!container {
  width: 100% !important
}

.container {
  width: 100%
}

@media (min-width: 576px) {
  .\!container {
    max-width: 576px !important
  }

  .container {
    max-width: 576px
  }
}

@media (min-width: 768px) {
  .\!container {
    max-width: 768px !important
  }

  .container {
    max-width: 768px
  }
}

@media (min-width: 992px) {
  .\!container {
    max-width: 992px !important
  }

  .container {
    max-width: 992px
  }
}

@media (min-width: 1200px) {
  .\!container {
    max-width: 1200px !important
  }

  .container {
    max-width: 1200px
  }
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.collapse {
  visibility: collapse
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: sticky
}

.inset-0 {
  inset: 0px
}

.bottom-0 {
  bottom: 0px
}

.left-1\/2 {
  left: 50%
}

.isolate {
  isolation: isolate
}

.z-10 {
  z-index: 10
}

.m-0 {
  margin: 0px
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem
}

.my-\[160px\] {
  margin-top: 160px;
  margin-bottom: 160px
}

.-mt-20 {
  margin-top: -5rem
}

.mb-1 {
  margin-bottom: 0.25rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.mb-4 {
  margin-bottom: 1rem
}

.mb-5 {
  margin-bottom: 1.25rem
}

.mb-8 {
  margin-bottom: 2rem
}

.ml-1 {
  margin-left: 0.25rem
}

.ml-2 {
  margin-left: 0.5rem
}

.ml-4 {
  margin-left: 1rem
}

.mr-1 {
  margin-right: 0.25rem
}

.mr-4 {
  margin-right: 1rem
}

.mr-5 {
  margin-right: 1.25rem
}

.mt-2 {
  margin-top: 0.5rem
}

.mt-4 {
  margin-top: 1rem
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.contents {
  display: contents
}

.hidden {
  display: none
}

.h-1 {
  height: 0.25rem
}

.h-1\.5 {
  height: 0.375rem
}

.h-24 {
  height: 6rem
}

.h-4 {
  height: 1rem
}

.h-6 {
  height: 1.5rem
}

.h-\[405px\] {
  height: 405px
}

.h-full {
  height: 100%
}

.min-h-screen {
  min-height: 100vh
}

.w-1 {
  width: 0.25rem
}

.w-1\.5 {
  width: 0.375rem
}

.w-1\/3 {
  width: 33.333333%
}

.w-24 {
  width: 6rem
}

.w-4 {
  width: 1rem
}

.w-6 {
  width: 1.5rem
}

.w-auto {
  width: auto
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content
}

.w-full {
  width: 100%
}

.w-max {
  width: max-content
}

.max-w-full {
  max-width: 100%
}

.flex-1 {
  flex: 1 1 0%
}

.flex-shrink {
  flex-shrink: 1
}

.shrink {
  flex-shrink: 1
}

.flex-grow {
  flex-grow: 1
}

.grow {
  flex-grow: 1
}

.border-collapse {
  border-collapse: collapse
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-pointer {
  cursor: pointer
}

.resize {
  resize: both
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.flex-row {
  flex-direction: row
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-center {
  align-items: center
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.gap-1 {
  gap: 0.25rem
}

.gap-10 {
  gap: 2.5rem
}

.gap-2 {
  gap: 0.5rem
}

.gap-4 {
  gap: 1rem
}

.gap-8 {
  gap: 2rem
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse))
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}

.overflow-hidden {
  overflow: hidden
}

.overflow-x-auto {
  overflow-x: auto
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.whitespace-nowrap {
  white-space: nowrap
}

.rounded-full {
  border-radius: 9999px
}

.rounded-lg {
  border-radius: 8px
}

.rounded-sm {
  border-radius: 4px
}

.border {
  border-width: 1px
}

.border-0 {
  border-width: 0px
}

.border-2 {
  border-width: 2px
}

.border-x-0 {
  border-left-width: 0px;
  border-right-width: 0px
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px
}

.border-b {
  border-bottom-width: 1px
}

.border-t {
  border-top-width: 1px
}

.border-solid {
  border-style: solid
}

.border-none {
  border-style: none
}

.border-gray-40 {
  --tw-border-opacity: 1;
  border-color: rgb(234 234 234 / var(--tw-border-opacity))
}

.border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgb(222 222 222 / var(--tw-border-opacity))
}

.border-primary-600 {
  --tw-border-opacity: 1;
  border-color: rgb(78 94 130 / var(--tw-border-opacity))
}

.border-success-500 {
  --tw-border-opacity: 1;
  border-color: rgb(24 170 60 / var(--tw-border-opacity))
}

.border-y-gray-50 {
  --tw-border-opacity: 1;
  border-top-color: rgb(222 222 222 / var(--tw-border-opacity));
  border-bottom-color: rgb(222 222 222 / var(--tw-border-opacity))
}

.bg-\[\#1f1f1f\] {
  --tw-bg-opacity: 1;
  background-color: rgb(31 31 31 / var(--tw-bg-opacity))
}

.bg-brand-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 107 45 / var(--tw-bg-opacity))
}

.bg-danger-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(233 56 12 / var(--tw-bg-opacity))
}

.bg-gray-10 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity))
}

.bg-gray-20 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity))
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}

.bg-primary-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(78 94 130 / var(--tw-bg-opacity))
}

.bg-success-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 255 228 / var(--tw-bg-opacity))
}

.bg-success-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(24 170 60 / var(--tw-bg-opacity))
}

.bg-transparent {
  background-color: transparent
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-opacity-10 {
  --tw-bg-opacity: 0.1
}

.bg-opacity-5 {
  --tw-bg-opacity: 0.05
}

.object-cover {
  object-fit: cover
}

.p-0 {
  padding: 0px
}

.p-3 {
  padding: 0.75rem
}

.p-4 {
  padding: 1rem
}

.p-6 {
  padding: 1.5rem
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.pb-20 {
  padding-bottom: 5rem
}

.pb-6 {
  padding-bottom: 1.5rem
}

.pl-1 {
  padding-left: 0.25rem
}

.pl-2 {
  padding-left: 0.5rem
}

.pr-1 {
  padding-right: 0.25rem
}

.pr-2 {
  padding-right: 0.5rem
}

.pt-1 {
  padding-top: 0.25rem
}

.pt-3 {
  padding-top: 0.75rem
}

.pt-4 {
  padding-top: 1rem
}

.pt-5 {
  padding-top: 1.25rem
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-justify {
  text-align: justify
}

.text-end {
  text-align: end
}

.align-middle {
  vertical-align: middle
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 1.4375;
  font-weight: 700
}

.text-5xl {
  font-size: 2.5rem;
  line-height: 1.375;
  font-weight: 700
}

.text-base {
  font-size: 1rem;
  line-height: 1.4375
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.4375
}

.text-md {
  font-size: 16px
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.4375
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.4375
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1.4375
}

.text-xxs {
  font-size: 11px
}

.font-bold {
  font-weight: 700
}

.font-medium {
  font-weight: 500
}

.font-regular {
  font-weight: 400
}

.font-semibold {
  font-weight: 600
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.italic {
  font-style: italic
}

.ordinal {
  --tw-ordinal: ordinal;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
}

.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
}

.leading-5 {
  line-height: 1.25rem
}

.leading-7 {
  line-height: 1.75rem
}

.leading-snug {
  line-height: 1.375
}

.text-base {
  --tw-text-opacity: 1;
  color: rgb(31 31 31 / var(--tw-text-opacity))
}

.text-branded {
  --tw-text-opacity: 1;
  color: rgb(237 107 45 / var(--tw-text-opacity))
}

.text-muted {
  --tw-text-opacity: 1;
  color: rgb(125 125 125 / var(--tw-text-opacity))
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(56 74 115 / var(--tw-text-opacity))
}

.text-success {
  --tw-text-opacity: 1;
  color: rgb(24 170 60 / var(--tw-text-opacity))
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline
}

.line-through {
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through
}

.no-underline {
  -webkit-text-decoration-line: none;
          text-decoration-line: none
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.opacity-0 {
  opacity: 0
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline {
  outline-style: solid
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.\!filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.transition-colors {
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
}

.hover\:cursor-pointer:hover {
  cursor: pointer
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(125 125 125 / var(--tw-bg-opacity))
}

.hover\:bg-gray-20:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity))
}

.hover\:bg-success-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(176 253 187 / var(--tw-bg-opacity))
}

.hover\:text-base:hover {
  font-size: 1rem;
  line-height: 1.4375;
  --tw-text-opacity: 1;
  color: rgb(31 31 31 / var(--tw-text-opacity))
}

.hover\:text-branded:hover {
  --tw-text-opacity: 1;
  color: rgb(237 107 45 / var(--tw-text-opacity))
}

.hover\:text-muted:hover {
  --tw-text-opacity: 1;
  color: rgb(125 125 125 / var(--tw-text-opacity))
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(56 74 115 / var(--tw-text-opacity))
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline
}

.hover\:no-underline:hover {
  -webkit-text-decoration-line: none;
          text-decoration-line: none
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

@media (min-width: 576px) {
  .min-\[576px\]\:ml-4 {
    margin-left: 1rem
  }

  .min-\[576px\]\:mt-0 {
    margin-top: 0px
  }
}

@media (min-width: 768px) {
  .md\:mb-0 {
    margin-bottom: 0px
  }

  .md\:mr-6 {
    margin-right: 1.5rem
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .md\:pr-6 {
    padding-right: 1.5rem
  }

  .md\:text-left {
    text-align: left
  }

  .md\:text-8xl {
    font-size: 3.75rem;
    line-height: 1.367;
    font-weight: 700
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.4375
  }

  .min-\[768px\]\:h-\[520px\] {
    height: 520px
  }

  .min-\[768px\]\:max-w-\[720px\] {
    max-width: 720px
  }

  .min-\[768px\]\:rounded-\[14px\] {
    border-radius: 14px
  }

  .min-\[768px\]\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }
}

@media (min-width: 992px) {
  .lg\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem
  }

  .lg\:mb-20 {
    margin-bottom: 5rem
  }

  .lg\:flex {
    display: flex
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .lg\:justify-stretch {
    justify-content: stretch
  }

  .lg\:gap-10 {
    gap: 2.5rem
  }

  .lg\:rounded-sm {
    border-radius: 4px
  }

  .lg\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .lg\:rounded-b-sm {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
  }

  .lg\:rounded-t-sm {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
  }

  .lg\:border-x {
    border-left-width: 1px;
    border-right-width: 1px
  }

  .lg\:p-6 {
    padding: 1.5rem
  }

  .lg\:px-0 {
    padding-left: 0px;
    padding-right: 0px
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem
  }

  .lg\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .min-\[992px\]\:h-\[620px\] {
    height: 620px
  }

  .min-\[992px\]\:max-w-\[960px\] {
    max-width: 960px
  }
}

@media (min-width: 1200px) {
  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 1.4375;
    font-weight: 700
  }

  .xl\:text-9xl {
    font-size: 6rem;
    line-height: 1.367;
    font-weight: 700
  }

  .xl\:font-medium {
    font-weight: 500
  }

  .min-\[1200px\]\:h-\[900px\] {
    height: 900px
  }

  .min-\[1200px\]\:max-w-\[1140px\] {
    max-width: 1140px
  }
}

@media (min-width: 1280px) {
  .min-\[1280px\]\:max-w-\[1400px\] {
    max-width: 1400px
  }
}
