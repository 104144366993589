$black: #000 !default;
$white: #fff !default;
$orange: rgb(237, 107, 45);
$cyan: #75a6b9 !default;
$green: #28a745 !default;
$yellow: #ffc107 !default;
$red: #dc3545 !default;

$gray-100: #f9f9f9;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #bfbfbf;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #181d1b !default;

$primary: $orange;
$secondary: $cyan;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$body-color: $gray-900 !default;
$border-radius: 5px !default;
$font-weight-semi-bold: 500;
$border-color: $gray-300 !default;
$font-weight-bold: 600;
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol" !default;
$font-weight-normal: 400 !default;
$spacer: 1rem !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$border-width: 1px !default;
$hr-border-width: $border-width !default;
$hr-border-color: rgba($black, 0.1) !default;
$image-loading-bg: $gray-200;
