@import "~styles/base";

html {
  height: 100%;
}

body {
  height: 100%;
  padding-left: env(safe-area-inset-left, 0);
  padding-right: env(safe-area-inset-right, 0);
  &.modal-is-open {
    height: auto;
  }
}

// The Main React App Div Id
// Let the minimum height be 100% and use flexbox
// to allow for our footer to always be aligned to the bottom
// of the screen.
#app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  // background-color: aqua; // For Debugging purposes only
}

// Specifies the main content of a document.
// We let the main content area grow to fill the
// 100% - this will push the footer to the bottom of
// the screen if there is lille content.
//
// Therefor, for each "App" that contains the footer
// we should make sure to use the <main></main> tag for
// the ...ehhh main content :)
#app > main {
  flex: 1 0 auto;
  // background-color: aquamarine; // For Debugging purposes only
}

// Makes sure that the footer doesnt shrink
// while flex box does its job at pushing the footer to
// the bottom of the screen.
#app > footer {
  flex-shrink: 0;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
